import PricesService from '../../services/prices.service';

const initialState = {
    prices: [],
    isFetching: false,
    isComplete: false,
    selectAll: false
}

export const prices = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchPrices({commit}, params) {
            commit('LOADING_STARTED');

            PricesService.fetchPrices(params).then((response) => {
                commit('LOADING_COMPLETED');
                commit('PRICES_FETCHED', response.data);
                return Promise.resolve(response);
            }, (err) => {
                commit('LOADING_ERROR');
                alert(err)
                return Promise.reject(err);
            });
        },
        reassignPrice({commit}, data) {
            commit('LOADING_STARTED');
            PricesService.reassignPrice(data.price.id, data.article.id).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                },
                (err) => {
                    commit('LOADING_ERROR');
                    alert(err)
                    return Promise.reject(err);
                });
        },
        reassignMultiplePrices({commit}, data) {
            commit('LOADING_STARTED');

            PricesService.reassignMultiplePrices(data.prices, data.article).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                },
                (err) => {
                    commit('LOADING_ERROR');
                    alert(err)
                    return Promise.reject(err);
                });
        },
        reassignArticle({commit}, data) {
            commit('LOADING_STARTED');
            PricesService.reassignSupplierArticle(data.price.id, data.article.id).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                },
                (err) => {
                    commit('LOADING_ERROR');
                    alert(err)
                }
            );
        },
        invalidatePrice({commit}, data) {
            commit('LOADING_STARTED');
            PricesService.invalidatePrice(data.price.id).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                },
                (err) => {
                    commit('LOADING_ERROR');
                    alert(err)
                }
            );
        },
        deletePrice({commit}, priceId) {
            commit('LOADING_STARTED');
            PricesService.deletePrice(priceId).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                },
                (err) => {
                    commit('LOADING_ERROR');
                    alert(err)
                }
            );
        },
        deleteMultiplePrices({commit}, data) {
            commit('LOADING_STARTED');

            PricesService.deleteMultiplePrices(data.prices).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                },
                (err) => {
                    commit('LOADING_ERROR');
                    console.log(err)
                    return Promise.reject(err);
                });
        },

        deAssignMultiplePrices({commit}, data) {
            commit('LOADING_STARTED');

            PricesService.deAssignMultiplePrices(data.prices).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                },
                (err) => {
                    commit('LOADING_ERROR');
                    console.log(err)
                    return Promise.reject(err);
                });
        },

        clearCompletedStatus({commit}) {
            commit('CLEAR_COMPLETED');
        },
        acceptProposals({commit}, data) {
            commit('LOADING_STARTED');

            PricesService.acceptProposals(data.prices).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                }, (error) => {
                    commit('LOADING_ERROR');
                    console.log(error)
                    return Promise.reject(error);
                }
            )
        },
        acceptSecondaryProposals({commit}, data) {

            commit('LOADING_STARTED');

            PricesService.acceptSecondaryProposals(data.prices).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                }, (error) => {
                    commit('LOADING_ERROR');
                    console.log(error)
                    return Promise.reject(error);
                }
            )
        },
        rejectProposedArticles({commit}, data) {
            commit('LOADING_STARTED');

            PricesService.rejectProposedArticles(data.prices).then(
                (response) => {
                    commit('LOADING_COMPLETED');
                    return Promise.resolve(response);
                },
                (err) => {
                    commit('LOADING_ERROR');
                    console.log(err)
                    return Promise.reject(err);
                });
        },

        selectAll: ({commit}) => {
            commit('SELECT_ALL')
        },
        deselectAll: ({commit}) => {
            commit('DESELECT_ALL')
        }

    },
    mutations: {
        LOADING_STARTED(state) {
            state.isComplete = false;
            state.isFetching = true;
        },
        LOADING_ERROR(state) {
            state.isComplete = false;
            state.isFetching = false;
        },
        LOADING_COMPLETED(state) {
            state.isComplete = true
            state.isFetching = false;
        },
        PRICES_FETCHED(state, data) {
            state.prices = data;
        },
        CLEAR_COMPLETED(state) {
            state.isComplete = false;
        },
        SELECT_ALL(state) {
            state.selectAll = true
        },
        DESELECT_ALL(state) {
            state.selectAll = false
        }
    }
}